import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getUserVendingPointSummaries({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/admin/user_vending_point_summaries',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
      { timeout: 8000 },
    )
  },
  getUserVendingPointDailySummaries({
    date,
    interval,
    userId,
    endDate = null,
  }) {
    return axiosIns.get(
      `/admin/user_vending_point_summaries/daily_list/${userId}`,
      {
        params: snakecaseKeys({
          date,
          interval,
          userId,
          endDate,
        }),
      },
      { timeout: 8000 },
    )
  },
}
