import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getRebateSystemUnits() {
    return axiosIns.get('/admin/rebate_system_units')
  },
  createRebateSystemUnit({
    name,
    description,
    evaluationCycleSpan,
    rebateSystemUnitRebatesAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      rebateSystemUnit: {
        name,
        description,
        evaluationCycleSpan,
        rebateSystemUnitRebatesAttributes,
      },
    }))

    return axiosIns.post('/admin/rebate_system_units', formData)
  },
  updateRebateSystemUnit({
    id,
    name,
    description,
    evaluationCycleSpan,
    rebateSystemUnitRebatesAttributes,
    logicsAttributes = [],
  }) {
    const formData = serialize(snakecaseKeys({
      rebateSystemUnit: {
        id,
        name,
        description,
        evaluationCycleSpan,
        rebateSystemUnitRebatesAttributes,
        logicsAttributes,
      },
    }))

    return axiosIns.put(`/admin/rebate_system_units/${id}`, formData)
  },
  deleteRebateSystemUnit(id) {
    return axiosIns.delete(`/admin/rebate_system_units/${id}`)
  },
}
