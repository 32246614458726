import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getUserContributionRatioSummaries({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/admin/user_contribution_ratio_summaries',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
      { timeout: 8000 },
    )
  },
  getUserContributionRatioDailySummaries({
    date,
    interval,
    userId,
    endDate = null,
  }) {
    return axiosIns.get(
      `/admin/user_contribution_ratio_summaries/daily_list/${userId}`,
      {
        params: snakecaseKeys({
          date,
          interval,
          userId,
          endDate,
        }),
      },
      { timeout: 8000 },
    )
  },
}
