import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getPaySystemUnits() {
    return axiosIns.get('/admin/pay_system_units')
  },
  createPaySystemUnit({
    name,
    description,
    evaluationCycleSpan,
    amount,
    counter,
    isNegative,
    type,
    roundingMethod,
    roundingDigit,
  }) {
    const formData = serialize(snakecaseKeys({
      paySystemUnit: {
        name,
        description,
        evaluationCycleSpan,
        amount,
        counter,
        isNegative,
        type,
        roundingMethod,
        roundingDigit,
      },
    }))

    return axiosIns.post('/admin/pay_system_units', formData)
  },
  updatePaySystemUnit({
    id,
    name,
    description,
    evaluationCycleSpan,
    amount,
    counter,
    isNegative,
    type,
    roundingMethod,
    roundingDigit,
    logicsAttributes = [],
  }) {
    const formData = serialize(snakecaseKeys({
      paySystemUnit: {
        name,
        description,
        evaluationCycleSpan,
        amount,
        counter,
        isNegative,
        type,
        roundingMethod,
        roundingDigit,
        logicsAttributes,
      },
    }))

    return axiosIns.put(`/admin/pay_system_units/${id}`, formData)
  },
  deletePaySystemUnit(id) {
    return axiosIns.delete(`/admin/pay_system_units/${id}`)
  },
}
