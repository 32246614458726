import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getEarningSummaries({ date, interval, endDate = null }) {
    return axiosIns.get('/admin/earning_summaries', {
      params: snakecaseKeys({
        date,
        interval,
        endDate,
      }),
    })
  },
  getUserEarningDailySummaries({
    date,
    interval,
    userId,
    endDate = null,
  }) {
    return axiosIns.get(`/admin/earning_summaries/daily_list/${userId}`, {
      params: snakecaseKeys({
        date,
        interval,
        userId,
        endDate,
      }),
    })
  },
}
