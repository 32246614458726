<template>
  <div>
    <interval-date-picker
      v-if="showDatePicker"
      :year="false"
      class="px-3"
      @date-updated="date = $event"
      @end-date-updated="endDate = $event"
      @interval-updated="interval = $event"
    />

    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      grow
    >
      <v-tab
        v-for="comp in comps"
        :key="`tab[${comp.value}]`"
      >
        {{ comp.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      touchless
      style="background: transparent"
    >
      <v-tab-item
        v-for="comp in comps"
        :key="`tabItems[${comp.value}]`"
      >
        <keep-alive>
          <component
            :is="comp.value"
            :key="`${comp.value}-${date}-${interval}-${endDate}`"
            :date="date"
            :interval="+interval"
            :end-date="endDate"
            :target-time-text="targetTimeText"
          />
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  ref,
  computed,
  inject,
  toRefs,
} from '@vue/composition-api'
import { format, addDays } from 'date-fns'
import useQueryString from '@/views/composable/useQueryString'
import IntervalDatePicker from '@/views/components/util/filter/IntervalDatePicker.vue'
import Payments from '@/views/components/hr/Payments.vue'
import Kpi from '@/views/components/hr/KPI.vue'

export default {
  components: {
    IntervalDatePicker,
    Payments,
    Kpi,
  },
  props: {
    showDatePicker: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { route } = useQueryString()
    const currentTabIdx = ref(0)
    const currentClubMeta = inject('currentClubMeta', { today: new Date().toISOString().substr(0, 10) })
    const date = ref(route.value.query.date || currentClubMeta.today)
    const interval = ref(Number(route.value.query.interval) || 0)
    const endDate = ref(route.value.query.endDate)
    const comps = [
      { text: '給料', value: 'Payments' },
      { text: 'KPI', value: 'Kpi' },
    ]

    const targetTimeText = computed(() => {
      if (endDate.value) return `${date.value}~${endDate.value}分`

      let title
      const startAt = date.value.split('-').join('/')
      let endAt

      switch (interval.value) {
        case 0:
          title = `${date.value.split('-').join('/')}分`
          break

        case 1:
          endAt = format(
            addDays(new Date(startAt), 7),
            'yyyy/MM/dd',
          )

          title = `${startAt} ~ ${endAt}分`
          break

        case 2:
          title = `${startAt.match(/(\d{4}\/\d{2})/)[1]}月分`
          break

        default:
          break
      }

      return title
    })

    return {
      // data
      currentTabIdx,
      date,
      interval,
      comps,
      currentClubMeta,
      endDate,

      // computed
      targetTimeText,
    }
  },
}
</script>
